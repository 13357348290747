import * as React from 'react';
import PropTypes from 'prop-types';

import QuotationScreen from '../../images/odoo-quotation';

const Sales = ({ bgcolor }) => (
  <div className={bgcolor}>
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 className="text-base font-semibold leading-6 tracking-wide text-indigo-600 uppercase">
          Send polished quotations
        </h2>
        <p className="mt-2 text-3xl font-extrabold leading-9 text-gray-900">
          Sales
        </p>
        <p className="my-4 text-lg leading-7 text-gray-500">
          Automate operations, focus on customer relationships and revenue.
        </p>
        <QuotationScreen />
      </div>
      <div className="mt-12 lg:mt-0 lg:col-span-2">
        <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Clean quotations
              </dt>
              <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                <span className="text-base leading-6 text-gray-500">
                  Send clear and complete quotations to your prospects. Add
                  product descriptions, beautiful images, and additional
                  information simply by dragging and dropping building blocks.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Order & contract sorting
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Invoice customers based on time and materials, easily record
                  contracts and track invoicing phases, maintain renewal and
                  upselling opportunities, and manage your subscriptions with
                  Odoo&apos;s recurring contracts.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Pricelists
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Design, implement, and adjust your pricing strategy to
                  maximize revenue. Use pricelist rules to compute the right
                  price based on customer conditions. Add discounts and coupons.
                </span>
              </dd>
            </div>
          </div>
          <div className="flex space-x-3">
            {/* Heroicon name: check */}
            <svg
              className="flex-shrink-0 w-6 h-6 text-green-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div className="space-y-2">
              <dt className="text-lg font-medium leading-6 text-gray-900">
                Customer portal
              </dt>
              <dd className="flex space-x-3">
                <span className="text-base leading-6 text-gray-500">
                  Let your customers help themselves The customer portal gives
                  your customers access to their quotations, sales orders, and
                  delivery orders. Save time and use <strong>Odoo Sign</strong>{' '}
                  to easily get signatures on NDAs, contracts, or any PDF
                  document.
                </span>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
);

Sales.propTypes = {
  bgcolor: PropTypes.string,
};

Sales.defaultProps = {
  bgcolor: 'bg-white',
};

export default Sales;
